<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="Start Date"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.startDate"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="End Date"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-btn color="primary" @click="search" class="mr-2">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn color="success" @click="excel">
            <v-icon>
              mdi-file-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-data-table :headers="headers" :items="items" dense fixed-header>
        <template v-slot:item.no="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.panelCodeExtItemPrice="{ item }">
          {{ formatPrice(item.panelCodeExtItemPrice) }}
        </template>
        <template v-slot:item.total="{ item }">
          {{ formatPrice(item.panelCodeExtItemPrice * item.qty) }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import fileDownload from "js-file-download";

export default {
  name: "report-inventory-ho",
  data() {
    return {
      params: {
        startDate: moment()
          .startOf("month")
          .format("yyyy-MM-DD"),
        endDate: moment()
          .endOf("month")
          .format("yyyy-MM-DD"),
      },
      menuStartDate: false,
      menuEndDate: false,
      items: [],
      headers: [
        {
          id: 1,
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Date",
          sortable: false,
          value: "date",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "HO#",
          sortable: false,
          value: "name",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "MFR",
          sortable: false,
          value: "mfr",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Part Number",
          sortable: false,
          value: "panelCodePartNumber",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Part Name",
          sortable: false,
          value: "partName",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Part Desc",
          sortable: false,
          value: "partDesc",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "U/M",
          sortable: false,
          value: "defaultUm",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "QTY",
          sortable: false,
          value: "qty",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Unit Price",
          align: "center",
          sortable: false,
          value: "panelCodeExtItemPrice",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Total Price",
          align: "center",
          sortable: false,
          value: "total",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    async excel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/transactionHOExcel", this.params)
        .then(response => {
          fileDownload(response.data, `transaction-ho.xlsx`);
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async search() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/transactionHO", this.params)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
